import React from 'react';
import styles from './SucessoForm.module.css';
import { useTranslation } from 'react-i18next';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { Button } from 'antd';



export default function SucessoForm({ isDarkTheme, data, setData }) {

    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <FaRegCircleCheck className={styles.icon} />
            <p className={styles.title}>{t('documentos.form_validated')}</p>
            <p className={styles.message}>{data.mensagemSucessoForm}</p>
            <p className={styles.thankYou}>{t('documentos.thankYou')}</p>
            <Button
                type="primary"
                htmlType="submit"
                className={styles.submitButton}
                onClick={() => {
                    
                    setData((prevData) => ({
                        ...prevData,
                        sucessoForm: false,
                        mensagemSucessoForm: ''
                    }));


                 }}
            >
                {t('documentos.form_novo_submit')}
            </Button>

        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './styles.css';
import './styles/index.css';
import { AppRoutes } from './Routes';
import queryString from 'query-string';
import styles from './App.module.css';
import i18n from './i18n/i18n';
import Header from './layout/Header';

export default function App() {

    const location = useLocation();
    const navigate = useNavigate();

  
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [parametros, setParametros] = useState({
        n: '',
        docToken: '',
        language: '',
        formID:'',
        portalId:'6644558',
        propostaEncontrada: false
    });

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const nomeProposta = parsed.n || parsed.nome_proposta || parsed.propuesta_nombre ? String(parsed.n || parsed.nome_proposta || parsed.propuesta_nombre) : null;
        const docKey = parsed.token_acesso ? String(parsed.token_acesso) : null;

        let formKey = null;

        if (location.pathname.startsWith('/forms/')) {
            formKey = location.pathname.replace('/forms/', '');
            // console.log(formKey)
        }


        // console.log(formKey)

        if (location.pathname === '/pt/docs') {
            const updatedQuery = queryString.stringify({
                ...parsed,
                token_acesso: docKey,
            });
            navigate(`/doc?${updatedQuery}`, { replace: true });
        }

        if (parsed.nome_proposta && !parsed.n) {
            const { nome_proposta, ...remainingParams } = parsed;
            const updatedQuery = queryString.stringify({
                ...remainingParams,
                n: parsed.nome_proposta,
            });
            navigate(`/?${updatedQuery}`, { replace: true });
        }

        if (nomeProposta || docKey || formKey) {
            setParametros(prevState => ({
                ...prevState,
                n: nomeProposta || prevState.n,
                docToken: docKey || prevState.docToken,
                formID: formKey || prevState.formKey
            }));
        } 

    }, [location.search]);

    useEffect(() => {
        i18n.changeLanguage(parametros.language);
    }, [parametros.language]);

    useEffect(() => {
        document.body.classList.toggle(styles.darkThemeBg, isDarkTheme);
        document.body.classList.toggle(styles.whiteThemeBg, !isDarkTheme);
        return () => {
            document.body.classList.remove(styles.darkThemeBg);
            document.body.classList.remove(styles.whiteThemeBg);
        };
    }, [isDarkTheme]);


    return (
        <div className={`${styles.appContainer} ${isDarkTheme ? styles.darkTheme : styles.lightTheme}`}>

            {parametros.propostaEncontrada && (
                <Header
                    parametros={parametros}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                />
            )}

            <AppRoutes
                isDarkTheme={isDarkTheme}
                setIsDarkTheme={setIsDarkTheme}
                parametros={parametros}
                setParametros={setParametros}
            />
        </div>
    );
}

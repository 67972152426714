import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styles from './FormsAutomaticos.module.css';
import logo from '../../assets/logo-branco 3.svg';
import logobranco from '../../assets/logo-branco 2.svg';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import TodosCamposGerarForms from '../../components/Forms/TodosCamposGerarForms';
import SucessoForm from '../../components/Forms/EstadoDoForm/SucessoForm';

export default function FormsAutomaticos({ isDarkTheme, parametros, analytics, propostaData }) {



    const { t } = useTranslation();

    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    // const location = useLocation();
        
    const [data, setData] = useState({
        formKey: '',
        campos: [],
        titulo: '',
        descricao: '',
        sucessoForm: false,
        mensagemSucessoForm: ''
    });

    useEffect(() => {
        const fetchForms = async () => {
            if (!propostaData) return;

            const allCampos = propostaData.data.fields;
            // console.log(allCampos)
            // console.log('teste')

            setData((prevData) => ({
                ...prevData,
                campos: allCampos,
                titulo: propostaData.data.nome,
                descricao: propostaData.data.pequena_descricao
            }));

        };

        fetchForms();
    }, [propostaData]);


    return (
        <>
            <div className={styles.errorContainer}>
                <div className={`${isMobile ? styles.mobileLayout : styles.desktopLayout}`}>
                    <div className={`${styles.logoSection} ${isMobile ? '' : styles.desktopSpacing}`}>
                        <a
                            href={parametros.language === 'pt' ? 'https://importrust.com' : 'https://importrust.es'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={!isDarkTheme ? logo : logobranco}
                                alt="Logo"
                                className={styles.logo}
                            />
                        </a>

                        {!isMobile && (
                            <>
                                <h2 className={styles.title}>{t('login.import_experts')}</h2>
                                <div className={styles.statsSection}>
                                    <div className={styles.statsRow}>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <h3 className={styles.statNumber}>{analytics?.clientes_satisfeitos}</h3>
                                            <span className={styles.statText}>{t('login.satisfied_clients')}</span>
                                        </div>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <h3 className={styles.statNumber}>{analytics?.poupanca_media}</h3>
                                            <span className={styles.statText}>{t('login.average_savings')}</span>
                                        </div>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <h3 className={styles.statNumber}>{analytics?.poupanca_acumulada}</h3>
                                            <span className={styles.statText}>{t('login.total_savings')}</span>
                                        </div>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <div className={styles.stars}>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                            </div>
                                            <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className={styles.messageSection}>
                        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                            <div className={styles.form}>
                                {(data.campos && !data.sucessoForm) && (
                                    <TodosCamposGerarForms
                                        data={data}
                                        setData={setData}
                                        campos={data.campos}
                                        isDarkTheme={isDarkTheme}
                                        parametros={parametros}
                                    />
                                )}
                                {(data.campos && data.sucessoForm) && (
                                    <SucessoForm
                                        data={data}
                                        setData={setData}
                                        campos={data.campos}
                                        isDarkTheme={isDarkTheme}
                                        parametros={parametros}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {isMobile && (
                        <>
                            <h2 className={styles.title}>{t('login.import_experts')}</h2>
                            {isMobile && (
                                <div className={styles.starsFlex}>
                                    <div className={styles.stars}>
                                        <span>★</span>
                                        <span>★</span>
                                        <span>★</span>
                                        <span>★</span>
                                        <span>★</span>
                                    </div>
                                    <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                </div>
                            )}
                            <div className={styles.statsSection}>
                                <div className={styles.statsRow}>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>{analytics?.clientes_satisfeitos}</h3>
                                        <span className={styles.statText}>{t('login.satisfied_clients')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>{analytics?.poupanca_media}</h3>
                                        <span className={styles.statText}>{t('login.average_savings')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>{analytics?.poupanca_acumulada}</h3>
                                        <span className={styles.statText}>{t('login.total_savings')}</span>
                                    </div>
                                    {!isMobile && (
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <div className={styles.stars}>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                            </div>
                                            <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { Routes, Route, useParams } from "react-router-dom";
import axios from 'axios';
import PerfilDaPropostaPT from './pages/Propostas/PT/PerfilPropostaPT';
import PerfilPropuestaES from './pages/Propostas/ES/PerfilPropuestaES';
import Documentos from './pages/Documentos/EnvioDocumentos';
import Loading from './components/Elements/Loading/Loading';
import Error from './components/Error/Error';
import PropostaNaoEncontrada from './components/PropostaNaoEncontrada/PropostaNaoEncontrada';
import FormsAutomaticos from './pages/Forms/FormsAutomaticos';


export function AppRoutes({
    parametros,
    setParametros,
    isDarkTheme
}) {


    const [propostaData, setPropostaData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stateError, setLocalError] = useState(false);
    const [propostaNaoEncontrada, setLocalPropostaNaoEncontrada] = useState(false);

    const [analytics, setAnalytics] = useState(null);

    const resetStates = () => {
        setLoading(false);
        setLocalError(false);
        setLocalPropostaNaoEncontrada(false);
    };


    useEffect(() => {
        const fetchPropostaData = async () => {
            resetStates();
            setLoading(true);

            try {
                const requestUrl = `${process.env.REACT_APP_GET_PROPOSTA}?n=${parametros.n}`;
                const response = await axios.get(requestUrl);
                if (response.data.sucesso) {
                    const { data } = response;
                    const pais = response.data.data.proposta.pais;

                    setParametros(prev => ({
                        ...prev,
                        language: pais,
                        propostaEncontrada: true
                    }));

                    setPropostaData(data);
                } else {
                    setLocalPropostaNaoEncontrada(true);
                    setLocalError(false);
                }
            } catch (error) {
                setLocalPropostaNaoEncontrada(false);
                setLocalError(true);
            } finally {
                setLoading(false); // Garante que o loading seja desativado
            }
        };

        if (parametros.n) fetchPropostaData();
    }, [parametros.n, setParametros]);

    useEffect(() => {


        const fetchDataAnalytics = async () => {
            try {
                const requestUrl = `${process.env.REACT_APP_DATA_ANALYTICS}`;
                const response = await axios.get(requestUrl);
                // console.log('fetchDataAnalytics', response)
                if (response.data.sucesso) {
                    setAnalytics(response.data.data);
                }
            } catch (error) {
            } finally {
            }
        };


        const fetchDocumentos = async () => {
            resetStates();
            setLoading(true);

            try {
                const requestUrl = `${process.env.REACT_APP_GET_PROPOSTA}?token_acesso=${parametros.docToken}`;
                const response = await axios.get(requestUrl);
                // console.log(response)
                if (response.data.sucesso) {
                    const { data } = response;
                    const pais = response.data.data.proposta.pais;
                    setParametros(prev => ({
                        ...prev,
                        language: pais,
                        propostaEncontrada: false
                    }));
                    fetchDataAnalytics()
                    setPropostaData(data);
                } else {
                    setLocalError(false);
                }
            } catch (error) {
                setLocalError(true);
            } finally {
                setLoading(false); // Garante que o loading seja desativado
            }
        };

        if (parametros.docToken) fetchDocumentos();

    }, [parametros.docToken, setParametros]);





    useEffect(() => {


        const fetchDataAnalytics = async () => {
            try {
                const requestUrl = `${process.env.REACT_APP_DATA_ANALYTICS}`;
                const response = await axios.get(requestUrl);
                // console.log('fetchDataAnalytics', response)
                if (response.data.sucesso) {
                    setAnalytics(response.data.data);
                }
            } catch (error) {
            } finally {
            }
        };


        const fetchForms = async () => {
            resetStates();
            setLoading(true);

            // console.log(parametros)

            try {
                const requestUrl = `${process.env.REACT_APP_FORMS}?formId=${parametros.formID}&portalId=${parametros.portalId}`;
                const response = await axios.get(requestUrl);
                // console.log(response)
                if (response.data.sucesso) {
                    const { data } = response;
                    // console.log(response.data.data.pais)
                    const pais = response.data.data.pais;
                    // const pais = "pt";
                    setParametros(prev => ({
                        ...prev,
                        language: pais,
                        propostaEncontrada: false
                    }));
                    fetchDataAnalytics()
                    // console.log(data)
                    setPropostaData(data);
                } else {
                    setLocalError(false);
                }
            } catch (error) {
                setLocalError(true);
            } finally {
                setLoading(false); // Garante que o loading seja desativado
            }
        };

        if (parametros.formID) fetchForms();

    }, [parametros.formID, setParametros]);



    if (loading) {
        return <Loading isDarkTheme={isDarkTheme} />;
    }

    if (stateError) {
        return <Error isDarkTheme={isDarkTheme} parametros={parametros} />;
    }

    if (propostaNaoEncontrada) {
        return <PropostaNaoEncontrada isDarkTheme={isDarkTheme} parametros={parametros} />;
    }

    return (
        <Routes>
            {parametros.language === 'pt' && (
                <>
                    <Route
                        path="/"
                        element={
                            <PerfilDaPropostaPT
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                setParametros={setParametros}
                                propostaData={propostaData}
                            // onDataLoaded={() => setLocalLoading(false)}
                            />
                        }
                    />

                    <Route
                        path="/doc"
                        element={
                            <Documentos
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                setParametros={setParametros}
                                propostaData={propostaData}
                                loading={loading}
                                error={stateError}
                                analytics={analytics}
                            />
                        }
                    />

                    <Route
                        path="/forms/:formkey"
                        element={
                            <FormsAutomaticos
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                setParametros={setParametros}
                                propostaData={propostaData}
                                loading={loading}
                                error={stateError}
                                analytics={analytics}
                            />
                        }
                    />

                </>
            )}

            {parametros.language === 'es' && (
                <>
                    <Route
                        path="/"
                        element={
                            <PerfilPropuestaES
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                setParametros={setParametros}
                                propostaData={propostaData}
                                loading={loading}
                                error={stateError}
                            />
                        }
                    />
                    <Route
                        path="/doc"
                        element={
                            <Documentos
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                setParametros={setParametros}
                                propostaData={propostaData}
                                loading={loading}
                                error={stateError}
                                analytics={analytics}
                            />
                        }
                    />

                    <Route
                        path="/forms/:formkey"
                        element={
                            <FormsAutomaticos
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                setParametros={setParametros}
                                propostaData={propostaData}
                                loading={loading}
                                error={stateError}
                                analytics={analytics}
                            />
                        }
                    />
                </>
            )}
            <Route
                path="*"
                element={
                    <Error isDarkTheme={isDarkTheme} parametros={parametros} />
                }
            />
        </Routes>
    );
}

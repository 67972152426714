import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styles from './EnvioDocumentos.module.css';
import FormDocumentos from '../../components/Documentos/FormDocumentos';
import logo from '../../assets/logo-branco 3.svg';
import logobranco from '../../assets/logo-branco 2.svg';
import { useTranslation } from 'react-i18next';
import InfosBusinessDeveloperDocumentacao from '../../components/InfosBusinessDeveloperDocumentacao/InfosBusinessDeveloperDocumentacao';
import TodosCamposGerarSubmeterDocs from '../../components/Documentos/TodosCamposGerarSubmeterDocs';
import GestaoStatusDocumentos from '../../components/Documentos/GestaoStatusDocumentos';

export default function Documentos({ isDarkTheme, parametros, analytics, propostaData }) {

    // console.log(analytics)

    const { t } = useTranslation();

    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    const location = useLocation();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true); // Armazenar o token de acesso
    const [bdInfo, setBDInfo] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [data, setData] = useState({
        tokenAcesso: '',
        morada: '',
        codigoPostal: '',
        key: '',
        campos: [],
        // estadosDocumentos: [],
        status: '',
        tipoNegocio: '',
        motivo: ''
    });


    useEffect(() => {
        const fetchDocumentos = async () => {
            if (!propostaData) return;

            // const allStatesDocs = propostaData.data.proposta.documentacao.info;
            const allDocInfo = propostaData.data.proposta.documentacao.info;
            const allDocTipoDoc = propostaData.data.proposta.documentacao.tipos_documentos;
            const allStatesDocs = propostaData.data.proposta.documentacao.estado_documentos;


            setData((prevData) => ({
                ...prevData,
                campos: allDocTipoDoc,
                status: allDocInfo.status,
                tipoNegocio: allDocInfo.tipo_contrato,
                estadosDocumentos: allStatesDocs,
                motivo: allDocInfo.motivo
            }));

            setBDInfo(propostaData.data.proposta.user)
            setCliente(propostaData.data.proposta?.hubspot?.contacto)
        };

        fetchDocumentos();
    }, [propostaData]);


    return (
        <>
            <div className={styles.errorContainer}>
                <div className={`${isMobile ? styles.mobileLayout : styles.desktopLayout}`}>
                    <div className={`${styles.logoSection} ${isMobile ? '' : styles.desktopSpacing}`}>
                        <a
                            href={parametros.language === 'pt' ? 'https://importrust.com' : 'https://importrust.es'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={!isDarkTheme ? logo : logobranco}
                                alt="Logo"
                                className={styles.logo}
                            />
                        </a>

                        {cliente && (
                            <p className={`${styles.name} ${isDarkTheme ? styles.nameDark : styles.nameLight}`}>
                                {t('view.ola')}, {cliente.firstname} {cliente.lastname} 👋
                            </p>
                        )}

                        {!isMobile && (
                            <InfosBusinessDeveloperDocumentacao
                                bdInfo={bdInfo}
                                isDarkTheme={isDarkTheme}
                            />
                        )}
                        {!isMobile && (
                            <>
                                <h2 className={styles.title}>{t('login.import_experts')}</h2>
                                <div className={styles.statsSection}>
                                    <div className={styles.statsRow}>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <h3 className={styles.statNumber}>{analytics?.clientes_satisfeitos}</h3>
                                            <span className={styles.statText}>{t('login.satisfied_clients')}</span>
                                        </div>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <h3 className={styles.statNumber}>{analytics?.poupanca_media}</h3>
                                            <span className={styles.statText}>{t('login.average_savings')}</span>
                                        </div>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <h3 className={styles.statNumber}>{analytics?.poupanca_acumulada}</h3>
                                            <span className={styles.statText}>{t('login.total_savings')}</span>
                                        </div>
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <div className={styles.stars}>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                            </div>
                                            <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className={styles.messageSection}>
                        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                            <div className={styles.form}>
                                {data.campos && (
                                    <GestaoStatusDocumentos
                                        data={data}
                                        setData={setData}
                                        campos={data.campos}
                                        estado={data.estadosDocumentos}
                                        isDarkTheme={isDarkTheme}
                                        parametros={parametros}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {isMobile && (
                        <InfosBusinessDeveloperDocumentacao
                            bdInfo={bdInfo}
                            isDarkTheme={isDarkTheme}
                        />
                    )}
                    {isMobile && (
                        <>
                            <h2 className={styles.title}>{t('login.import_experts')}</h2>
                            {isMobile && (
                                <div className={styles.starsFlex}>
                                    <div className={styles.stars}>
                                        <span>★</span>
                                        <span>★</span>
                                        <span>★</span>
                                        <span>★</span>
                                        <span>★</span>
                                    </div>
                                    <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                </div>
                            )}
                            <div className={styles.statsSection}>
                                <div className={styles.statsRow}>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>{analytics?.clientes_satisfeitos}</h3>
                                        <span className={styles.statText}>{t('login.satisfied_clients')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>{analytics?.poupanca_media}</h3>
                                        <span className={styles.statText}>{t('login.average_savings')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>{analytics?.poupanca_acumulada}</h3>
                                        <span className={styles.statText}>{t('login.total_savings')}</span>
                                    </div>
                                    {!isMobile && (
                                        <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                            <div className={styles.stars}>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                            </div>
                                            <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

import React, { useState } from 'react';
import { Form, Input, Button, Upload, message, Space, Select } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import styles from './TodosCamposGerarForms.module.css';
import { useTranslation } from 'react-i18next';
// import { FaCheckCircle } from 'react-icons/fa';

const { Option } = Select;

export default function TodosCamposGerarForms({ data, setData, campos, isDarkTheme, parametros }) {

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);


    const [dataListas, setDataListas] = useState({
        listaMarcas: [],
        listaModelos: [],
        listaCidades: [],
        listaMunicipios: []
    });


    const handleSubmit = (values) => {

        setLoading(true)

        // Combina os valores do código postal
        if (values.codigo_postal_pt) {
            values.codigo_postal_pt = `${values.codigo_postal_pt.parte1}-${values.codigo_postal_pt.parte2}`;
        }

        const formData = new FormData();

        Object.keys(values).forEach((key) => {
            if (Array.isArray(values[key])) {
                values[key].forEach((fileObj) => {
                    if (fileObj.originFileObj) {
                        formData.append(`${key}[]`, fileObj.originFileObj);
                    }
                });
            } else {
                formData.append(key, values[key]);
            }
        });

        // formData.append('token_acesso', parametros.docToken);
        formData.append('formId', parametros.formID);
        formData.append('portalId', parametros.portalId);

        axios.post(`${process.env.REACT_APP_FORM_SUBMIT}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then((response) => {
                if (response.data.sucesso) {
                    //message.success(response.data.mensagem);
                    setData((prevData) => ({
                        ...prevData,
                        sucessoForm: true,
                        mensagemSucessoForm: response.data.mensagem
                    }));

                    form.resetFields();
                    setLoading(false)

                } else {

                    setData((prevData) => ({
                        ...prevData,
                        sucessoForm: false,
                    }));

                    message.error(response.data.mensagem);
                    setLoading(false)
                }
            })
            .catch(() => {
                message.error('Erro, contacte o responsável!');
                setLoading(false)
            });
    };



    const fetchMunicipio = async (provinciaSelecionada) => {
        try {

            const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MUNICIPIOS}?slug_cidade=${provinciaSelecionada}`;
            const response = await axios.get(requestUrl);

            setDataListas((prevData) => ({
                ...prevData,
                listaMunicipios: response.data.mensagem,
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const renderField = (field) => {
        // console.log(field)
        const labelClass = isDarkTheme ? styles.labelDark : styles.labelLight;

        switch (field.tipo) {
            case 'string':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                        </div>
                        <Form.Item
                            name={field.slug}
                            rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                        >
                            <Input placeholder={field.label} />
                        </Form.Item>

                    </div>
                );
            case 'email':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                        </div>
                        <Form.Item
                            name={field.slug}
                            rules={[
                                { required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` },
                                { type: 'email', message: t('documentos.email_invalido') } // Validação de e-mail
                            ]}
                        >
                            <Input placeholder={field.label} />
                        </Form.Item>
                    </div>
                );
            case 'phonenumber':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                        </div>
                        <Form.Item
                            name={field.slug}
                            rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                        >
                            <Input placeholder={field.label} />
                        </Form.Item>

                    </div>
                );
            case 'select':
                if (field.slug === 'municipio_qualificacoes') {
                    return (
                        <div className={styles.flexContainer} key={field.slug}>
                            <div className='flex gap-2 items-center'>
                                <p className={labelClass}>{field.label}</p>
                            </div>
                            <Form.Item
                                name={field.slug}
                                rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                            >
                                <Select placeholder="Selecione um município">
                                    {dataListas.listaMunicipios.map((municipio) => (
                                        <Option key={municipio.id} value={municipio.nome_municipio}>{municipio.nome_municipio}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    );

                } else {

                    return (
                        <div className={styles.flexContainer} key={field.slug}>
                            <div className='flex gap-2 items-center'>
                                <p className={labelClass}>{field.label}</p>
                            </div>
                            <Form.Item
                                name={field.slug}
                                rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                            >
                                {/* <Select placeholder="Selecione o tipo de documento"> */}
                                <Select
                                    placeholder="Selecione"
                                    onChange={(value) => {
                                        if (field.slug == 'provincia_qualificacoes') {
                                            fetchMunicipio(value);
                                            form.setFieldsValue({ municipio_qualificacoes: undefined }); // Resetar campo de municípios
                                        }
                                    }}
                                >
                                    {field.select_opcoes.map((campo) => (
                                        <Option key={campo.value} value={campo.label}>{campo.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                        </div>
                    );
                }
            // case 'select':
            //     if (field.slug === 'municipio_qualificacoes') {
            //         return (
            //             <div className={styles.flexContainer} key={field.slug}>
            //                 <div className='flex gap-2 items-center'>
            //                     <p className={labelClass}>{field.label} ss</p>
            //                 </div>
            //                 <Form.Item
            //                     name={field.slug}
            //                     rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
            //                 >
            //                     <Select placeholder="Selecione um município">
            //                         {dataListas.listaMunicipios.map((municipio) => (
            //                             <Option key={municipio.id} value={municipio.nome}>{municipio.nome}</Option>
            //                         ))}
            //                     </Select>
            //                 </Form.Item>
            //             </div>
            //         );
            //     }
            //     break;
            case 'codigo_postal':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>

                        </div>
                        <div className='flex gap-2 items-center'>
                            <Form.Item name={[field.slug, 'parte1']} rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                <Input placeholder="1150" type="text" maxLength={4} />
                            </Form.Item>
                            <Form.Item name={[field.slug, 'parte2']} rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                <Input placeholder="084" type="text" maxLength={3} />
                            </Form.Item>
                        </div>


                    </div>
                );
            case 'file':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                        </div>
                        <Form.Item
                            name={field.slug}
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e?.fileList}
                            rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                        >
                            <Upload multiple name={field.slug} beforeUpload={() => false}>
                                <Button
                                    icon={<UploadOutlined />}
                                    className={styles.uploadButton}
                                >
                                    {t('documentos.carregarDocumento')}
                                </Button>
                            </Upload>
                        </Form.Item>

                    </div>
                );
            default:
                return null;
        }
    };








    return (
        <>
            <div className={styles.container}>
                <p className={styles.title}>{data.titulo}</p>
                <p className={styles.instruction}>{data.descricao}</p>
            </div>
            <hr />
            <Form
                layout="vertical"
                className={styles.form}
                form={form}
                onFinish={handleSubmit}
            >
                <div className={styles.formGrid}>
                    {campos
                        .filter(campo => campo.hidden !== 1) // Remove campos ocultos
                        .sort((a, b) => a.order - b.order) // Ordena pelo campo "order"
                        .map(campo => renderField(campo))}
                </div>

                <hr />

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.submitButton}
                        disabled={loading}
                    >
                        {t('documentos.enviar')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

import React from 'react';
import { Form, Input, Button, Upload, message, Space, Select } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import styles from './TodosCamposGerarSubmeterDocs.module.css';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';

const { Option } = Select;

export default function TodosCamposGerarSubmeterDocs({ data, estado, setData, campos, isDarkTheme, parametros }) {

    const { t } = useTranslation();
    const [form] = Form.useForm();


    //console.log('estado', estado)


    const handleSubmit = (values) => {
        // console.log(values);

        // Combina os valores do código postal
        if (values.codigo_postal_pt) {
            values.codigo_postal_pt = `${values.codigo_postal_pt.parte1}-${values.codigo_postal_pt.parte2}`;
        }


        const formData = new FormData();

        Object.keys(values).forEach((key) => {
            if (Array.isArray(values[key])) {
                values[key].forEach((fileObj) => {
                    if (fileObj.originFileObj) {
                        formData.append(`${key}[]`, fileObj.originFileObj);
                    }
                });
            } else {
                formData.append(key, values[key]);
            }
        });

        formData.append('token_acesso', parametros.docToken);

        axios.post(`${process.env.REACT_APP_UPLOUD_DOCUMENTOS}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    setData((prevData) => ({
                        ...prevData,
                        status: response.data.documentacao_enviada,
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error('Erro, contacte o responsável!');
            });
    };


    const renderField = (field) => {
        const labelClass = isDarkTheme ? styles.labelDark : styles.labelLight;

        switch (field.tipo) {
            case 'string':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                            {estado?.find(doc => doc.slug === field.slug)?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {estado?.find(doc => doc.slug === field.slug)?.status !== 'accepted' && (
                            <Form.Item
                                name={field.slug}
                                rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                            >
                                <Input placeholder={field.label} />
                            </Form.Item>
                        )}
                    </div>
                );
            case 'select':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                            {estado?.find(doc => doc.slug === field.slug)?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {estado?.find(doc => doc.slug === field.slug)?.status !== 'accepted' && (
                            <Form.Item
                                name={field.slug}
                                rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                            >
                                <Select placeholder="Selecione o tipo de documento">
                                    {field.select_opcoes.map((campo) => (
                                        <Option key={campo.value} value={campo.value}>{campo.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </div>
                );
            case 'codigo_postal':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                            {estado?.find(doc => doc.slug === field.slug)?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {estado?.find(doc => doc.slug === field.slug)?.status !== 'accepted' && (
                            <div className='flex gap-2 items-center'>
                                <Form.Item name={[field.slug, 'parte1']} rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                    <Input placeholder="1150" type="text" maxLength={4} />
                                </Form.Item>
                                <Form.Item name={[field.slug, 'parte2']} rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                    <Input placeholder="084" type="text" maxLength={3} />
                                </Form.Item>
                            </div>

                        )}
                    </div>
                );
            case 'file':
                return (
                    <div className={styles.flexContainer} key={field.slug}>
                        <div className='flex gap-2 items-center'>
                            <p className={labelClass}>{field.label}</p>
                            {estado?.find(doc => doc.slug === field.slug)?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {estado?.find(doc => doc.slug === field.slug)?.status !== 'accepted' && (
                            <Form.Item
                                name={field.slug}
                                valuePropName="fileList"
                                getValueFromEvent={(e) => e?.fileList}
                                rules={[{ required: field.required == 1, message: `${field.label} ${t('documentos.e_obrigatorio')}` }]}
                            >
                                <Upload multiple name={field.slug} beforeUpload={() => false}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className={styles.uploadButton}
                                    >
                                        {t('documentos.carregarDocumento')}
                                    </Button>
                                </Upload>
                            </Form.Item>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };








    return (
        <>
            <div className={styles.container}>
                <p className={styles.title}>{t('documentos.titulo')}</p>
                <p className={styles.instruction}>{t('documentos.instrucaoEnvio')}</p>
            </div>
            <hr />
            <Form
                layout="vertical"
                className={styles.form}
                form={form}
                onFinish={handleSubmit}
            >
                {campos.map((campo) => renderField(campo))}
                <hr />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.submitButton}
                    >
                        {t('documentos.enviarDocumentos')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
